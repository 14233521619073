
.page-title-icon > div > svg{
   
   width: 215px;
   height: 55px;
   
   @media (min-width: 768px){
      width: 220px;
      height: 50px;
   }
}

